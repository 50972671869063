
import { Vue, Component, Ref } from 'vue-property-decorator'
import { parse } from 'papaparse'
// import parseDate from 'date-fns/parse'
import moment from 'moment'
import ListView from '@/components/ListView.vue'
import { Shop } from '@/types/shop'

const parseCSV = (file: File) => {
  return new Promise((resolve, reject) => {
    parse(file, {
      error: err => {
        reject(err)
      },
      complete: results => {
        return resolve(results.data)
      },
    })
  })
}

@Component({
  components: { ListView },
})
export default class ShiftListView extends Vue {
  filterDefinition = {}
  @Ref() readonly listView!: ListView
  filter: null | Record<string, any> = null

  uploadFile: File | null = null
  uploadData: any = null
  shops: Shop[] = []
  isUploading = false
  isAborting = false
  uploadingMessage = ''
  errorMessages: string[] = []

  get headers() {
    return [
      { text: 'ID', value: 'id' },

      { text: 'staff code', value: 'staffCode' },
      { text: 'staff name', value: 'staffName' },
      { text: 'date', value: 'date' },
      { text: 'start', value: 'start' },
      { text: 'end', value: 'end' },
    ]
  }

  created() {
    this.fetchShops()
  }

  async fetchShops(url?: string) {
    const { next, results } = await (url
      ? this.$api.http.get(url)
      : this.$api.shops().list())
    this.shops = this.shops.concat(results)
    if (next) this.fetchShops(next)
  }

  async onUploadFileChanged() {
    const file = this.uploadFile
    if (!file) return

    const data = (await parseCSV(file)) as any[]
    console.log(data)
    const code2shop: Record<string, string> = {}
    this.shops.forEach(s => {
      code2shop[s.code] = s.id
    })
    const uploadData = [] as any[]
    data.forEach((row, idx) => {
      if (idx === 0) return
      const parsed = {
        shop: code2shop[row[0]],
        staffCode: row[2],
        date: moment(row[1]).format('YYYY-MM-DD'),
        start: row[3],
        end: row[4],
      }
      uploadData.push(parsed)
    })

    this.uploadData = uploadData
  }

  async _upload(date: string, shop: Shop, data: any) {
    this.uploadingMessage = `${date} ${shop.name}のデータアップロード中`

    try {
      await this.$api.shifts().bulk(data)
    } catch (err) {
      this.errorMessages.push(
        `${date} ${shop.name}のデータアップロード中にエラーが発生しました。`
      )
    }
  }

  async upload() {
    const data = this.uploadData
    this.errorMessages = []
    if (!data) return

    let date2data = {} as any
    data.forEach((x: any) => {
      if (!date2data[x.date]) date2data[x.date] = []
      date2data[x.date].push(x)
    })
    const dates = Object.keys(date2data) as string[]
    dates.sort()
    this.isUploading = true
    for (let idx = 0; idx < dates.length; ++idx) {
      if (this.isAborting) break
      const date = dates[idx]
      const subdata = date2data[date]
      const shops = this.shops
      for (let shopIdx = 0; shopIdx < shops.length; ++shopIdx) {
        if (this.isAborting) break
        const shop = this.shops[shopIdx]
        await this._upload(
          date,
          shop,
          subdata.filter((x: any) => x.shop === shop.id)
        )
      }
    }
    this.uploadingMessage = ''
    this.isUploading = false
    this.isAborting = false
  }

  downloadTemplate() {
    const filename = 'template.csv'
    let rows = [
      ['店コード', '日付', 'スタッフコード', '開始時間', '終了時間'],
      ['101', '2021-07-01', '8001', '9:20', '20:30'],
    ]

    let content = rows.map(row => row.join(',')).join('\r\n')
    let bom = new Uint8Array([0xef, 0xbb, 0xbf])
    let blob = new Blob([bom, content], { type: 'text/csv' })

    if (window.navigator.msSaveBlob) {
      window.navigator.msSaveBlob(blob, filename)
    } else {
      let a = document.createElement('a')
      a.download = filename
      // a.target = '_blank'
      a.href = window.URL.createObjectURL(blob)

      // firefox対策． chromeの場合は append/remove をしなくていいらしい
      document.body.appendChild(a)
      a.click()
      document.body.removeChild(a)
    }
  }
}
